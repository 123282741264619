@font-face {
  font-family: 'Avenir Book';
  font-style: normal;
  src: local('Avenir Book'), local('avenir-book'),
  url('./avenir-book.woff2') format('woff2'), /* Chrome 26+, Opera 23+, Firefox 39+ */
  url('./avenir-book.woff') format('woff'); /* Chrome 6+, Firefox 3.6+, IE 9+, Safari 5.1+ */
}

@font-face {
  font-family: 'Avenir Light';
  font-style: normal;
  src: local('Avenir Light'), local('avenir-light'),
  url('./avenir-light.woff2') format('woff2'), 
  url('./avenir-light.woff') format('woff'); 
}

@font-face {
  font-family: 'Avenir Roman';
  font-style: normal;
  font-weight: 550;
  src: local('Avenir Roman'), local('avenir-roman'),
  url('./avenir-roman.woff2') format('woff2'), 
  url('./avenir-roman.woff') format('woff');
}

@font-face {
  font-family: 'Avenir Bold';
  font-style: normal;
  font-weight: 950;
  src: local('Avenir Bold'), local('avenir-bold'),
  url('./avenir-bold.woff2') format('woff2'), 
  url('./avenir-bold.woff') format('woff');
}